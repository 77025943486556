:root {
    --main: #111827;
    --main2: #1f2937;
    --themetext: rgb(35, 156, 255);
    --paragraph: #a6adb7;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
    background-color: var(--main);
    font-family: 'Poppins', sans-serif;
}

.wrapper {
    width: 100%;

}

nav {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;
    background-color: transparent;
    transition: .5s ease-in-out;
    border: 1px solid transparent;
}

.navbar label{
  padding: 10px 15px;
  border-radius: 10px;
  color: var(--themetext);
  border: 1px solid var(--themetext);
  display: none;
}

.enable-menu {
  max-height: 100% !important;
}

@media only screen and (max-width: 600px) {

  .header {
    background-image: url("../../public/img/mobil-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
  }

  nav {
    border-bottom: 1px solid var(--themetext);
    background-color: var(--main);
    flex-direction: column;
  }

  .toggler-hold {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .nav-links {
    flex-direction: column;
    max-height: 0;
    margin: 0;
    overflow: hidden;
  }

  .nav-item {
    text-align: center;
  }

  .navbar label{
    display: inline-block;
    margin: 10px;
  }
}

#toggler {
  display: none;
}

.nav-border {
  border-bottom: solid 1px var(--themetext);
  background-color: var(--main); 
  
}



.nav-links {
    list-style: none;
    display: flex;
    padding: 0;
    cursor: pointer;
  }
  
  .nav-item a {
    display: inline-block;
    padding: 10px 15px;
    text-decoration: none;
    color: white;
    font-size: 18px;
    transition: .4s ease-in-out;
    text-transform: uppercase;
  }

  .nav-item a:after {
    display:block;
    content: '';
    border-bottom: solid 3px var(--themetext);  
    transform: scaleX(0);  
    transition: transform 250ms ease-in-out;
  }
  .nav-item a:hover:after { transform: scaleX(1); }

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    height: 100vh;
    position: relative;
  }

  .header-text
  {
    flex: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
  }

  .header-text__content #welcome {
    margin: 0;
  }

  .header-text__content h1
  {
    font-size: 70px;
    margin: 45px 0 0 0;
    line-height: 50px;
  }

  .header-text__content #devtext
  {
    font-size: 50px;
    margin: 0 0 55px 0;
    font-weight: 200;
  }

  .header-text__content #cv-button {
    color: white;
    background-color: var(--themetext);
    text-decoration: none;
    padding: 20px 40px;
    font-family: 'Poppins', sans-serif;
  }

  .header-img
  {
    flex: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  h1 {
    color: var(--themetext);
  }

  .about-me {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  h2 {
    color: white;
    font-size: 50px;
  }

  h2 b {
    color: var(--themetext);
  }

  .about-me .experience {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
    gap: 50px;
  }

  .exp-holder {
    background-color: var(--main2);
    width: 350px;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
    padding: 30px 0;
  }

  .exp-holder h3 {
    font-weight: 500;
    margin-bottom: 0;
  }

  .exp-holder p {
    text-align: center;
    width: 250px;
    color: var(--paragraph);
  }

  .exp-holder a {
    text-decoration: none;
    color: white;
    background-color: var(--themetext);
    padding: 15px 30px;
  }

  .exp-icon1 {
    display: block;
    width: 150px;
    height: 150px;
    background-image: url("../../public/img/development.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .exp-icon2 {
    display: block;
    width: 150px;
    height: 150px;
    background-image: url("../../public/img/erp.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .exp-icon3 {
    display: block;
    width: 150px;
    height: 150px;
    background-image: url("../../public/img/mobile-development.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .mouse {
    width: 30px;
    height: 60px;
    border: 3px solid white;
    opacity: .4;
    border-radius: 60px;
    position: absolute;
    bottom: 20px;
    transition: 1s ease-in-out;
    &::before {
      content: '';
      width: 9px;
      height: 9px;
      position: absolute;
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
      background-color: white;
      border-radius: 50%;
      opacity: 1;
      animation: wheel 2s infinite;
      -webkit-animation: wheel 2s infinite;
    }
  }

  .hide-mouse {
    opacity: 0;
  }

  @keyframes wheel {
    to {
      opacity: 0;
      top: 40px;
    }
  }
  
  @-webkit-keyframes wheel {
    to {
      opacity: 0;
      top: 40px;
    }
  }

  .technologies {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

  }

  .tech-holder {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 20px;
    width: 1170px;
    flex-wrap: wrap;
  }

  .tech-holder > div {
    flex: 0 18%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--main2);
    border-radius: 10px;
    padding: 30px 0;
  }

  /*.technology {
    flex: 0 18%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--main2);
    border-radius: 10px;
    padding: 30px 0;
  }*/

  .technology img {
    width: 50px;
  }

  .technology .img-holder {
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .technology p {
    color: white;
    margin: 20px 0 0 0;
    text-align: center;
  }

  .contact {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 50px;
  }

  .contact-text {
    width: 1170px;
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: row;
    background-color: var(--main2);
    height: 400px;
    border-radius: 10px;
  }

  .contact-text__text {
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    height: 400px;
  }

  .contact-text__text p {
    color: white;
    font-size: 22px;
    margin: 5px 50px;
  }

  .contact-text__text p b {
    color: var(--themetext);
  }

  .contact-text__text a {
    text-decoration: none;
    color: white;
    background-color: var(--themetext);
    padding: 15px 25px;
    display: inline-block;
    width: 200px;
    margin: 25px 50px;
    text-align: center;
  }

  .contact-text__img {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contact-text__img img {
	  transform: translatey(0px);
	  animation: float 6s ease-in-out infinite;
    width: 50%;
  }

  @keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
}

@media only screen and (max-width: 600px) {

  .header-img {
    display: none;
  }

  .header-text {
    flex:100%;
    text-align: center;
    
  }

  .header-text p, h1 {
    text-align: center;
  }

  .header-text h1 {
    font-size: 58px;
    line-height: 65px;
    margin: 25px 0 0 0;
  }

  .header-text__content #devtext {
    font-size: 36px;
    margin-top: 20px;
  }

  h2 {
    font-size: 24px;
  }

  .experience {
    flex-wrap: wrap;
  }

  .tech-holder {
    width: 100%;
  }

  .tech-holder > div {
    flex: 0 1 40%;
  }

  .contact-text {
    flex-wrap: wrap;
    width: 85%;
    height: auto;
    margin: 0 auto;
    padding-bottom: 30px;
  }

  .contact-text__text {
    align-items: center;
  }

  .contact-text__text p {
    font-size: 20px;
  }

  .contact-text__text p {
    text-align: center;
  }
}